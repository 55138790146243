<template>
    <div class="dashboard">
        <el-row :gutter="15">
            <el-col :md="6" :sm="12" v-for="(item,index) in infoBox" class="info-wrap" :key="index">
                    <div class="info-box">
                        <span class="info-box-icon"><i :class="item.icon"></i></span>
                        <div class="info-box-count">
                            <span class="number">{{item.num}}</span>
                            <span>{{item.text}}</span>
                        </div>
                    </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
                <el-col :xs="24" :sm="12">
                    <defaultSec :title="'CALENDAR'">
                        <div class="section-item">
                            <el-empty description="描述文字"></el-empty>
                        </div>

<!--                        <el-calendar v-model="value"></el-calendar>-->
                    </defaultSec>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <defaultSec :title="'CALENDAR'">
                        <div class="section-item">
                            <div id="myChart1" class="chart-part"></div>
                        </div>
                    </defaultSec>
                </el-col>
        </el-row>

    </div>
</template>

<script>
    import defaultSec from '@c/defaultSection'

    import 'echarts/theme/macarons'

    export default {
        data() {
            return {
                value: new Date(),
                infoBox:[{
                    color:'yellow',
                    icon:'el-icon-search',
                    text:'User',
                    num:'12389'
                },{
                    color:'green',
                    icon:'el-icon-sold-out',
                    text:'User',
                    num:'12389'
                },{
                    color:'red',
                    icon:'el-icon-message',
                    text:'User',
                    num:'12389'
                },{
                    color:'blue',
                    icon:'el-icon-service',
                    text:'User',
                    num:'12389'
                }],
            }
        },
        components:{
            defaultSec
        },
        mounted(){

            this.initEchart()
        },
        methods: {
            initEchart(){
                let myChart1 = this.$echarts.init(document.getElementById('myChart1'),'macarons')
                let option1 = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        left: 'center',
                        bottom: '10',
                        data: [
                            'Battlefield',
                            'Call Of Duty',
                            'Counter Strike',
                            'Splatoon',
                            'Titanfall'
                        ]
                    },
                    calculable: true,
                    series: [
                        {
                            name: 'FPS GAME DATA',
                            type: 'pie',
                            roseType: 'radius',
                            radius: [15, 140],
                            center: ['50%', '38%'],
                            data: [
                                { value: 320, name: 'Battlefield' },
                                { value: 240, name: 'Call Of Duty' },
                                { value: 149, name: 'Counter Strike' },
                                { value: 400, name: 'Titanfall' },
                                { value: 524, name: 'Splatoon' }
                            ],
                            animationEasing: 'cubicInOut',
                            animationDuration: 2600
                        }
                    ]
                };
                myChart1.setOption(option1);
            },
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">
   @import "../assets/css/mixin";
    .dashboard{
      .default-section-wrap{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
      }
        .default-body{
            background: transparent !important;
        }
        .section-item,.chart-part{
            height: 560px;
        }
        //.el-calendar,.el-calendar-table thead th,.el-calendar__title{
        //    background: transparent;
        //    color: #ffffff;
        //}
        //.el-calendar-table .el-calendar-day:hover,.el-calendar-table td.is-selected{
        //    color: #209e91;
        //}
        //.el-calendar-table .el-calendar-day{
        //    height: 73px;
        //}
        .info-box{
            display: flex;
            //background: url("../assets/images/blur-bg-blurred.jpg") fixed;
            //background-position: 0 0;
            //background-size: cover;
            border: none;
            border-radius: 5px;
            position: relative;
            margin-bottom: 24px;
            padding-bottom: 15px;
            box-shadow: 0 5px 5px 0 rgba(0,0,0,.25);
            //color: #ffffff;
            @include dashboard_info_box_color();
            &-icon{
                float: left;
                width: 90px;
                display: block;
                height: 90px;
                font-size: 45px;
                text-align: center;
                line-height: 90px;
                color: inherit;
            }
            &-count{
                padding:13px 10px;
                text-align: center;
                span{
                    display: block;
                }
                .number{
                    font-size: 30px;
                }
            }
        }
        .info-desc{
            white-space: pre-line;
            color: #333333;
            margin: 5px 0
        }
        .flex-wrap{
            display: flex;
            justify-content: space-between;
        }
    }

</style>